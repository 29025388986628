import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import { H2 } from '../css/stylecomp'
import Footer from "../components/footer";

function ContactPage() {
  return (
    <Layout>
      <SEO
        title="Contact"
        keywords={[`data`, `science`, `consulting`]}
      />
      <div>
        <Section className="bg-very-lightest-grey border-solid border border-very-grey-light h-auto pt-8 pb-8 flex items-center" containerClass="">
          <div className="flex flex-col lg:w-full text-center">
            <H2 className="font-thin ">Contact us about your project. </H2>
            <div className="text-grey text-lg font-thin leading-normal py-4">
              Get in touch via email at info@davcon.se, or using the form below.
            </div>
          </div>
        </Section>
        <Section className="border-b border-solid border-very-grey-light" containerClass="py-10">

          <form className="mx-auto md:w-1/2 text-left" name="contact" action="/success" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact" />
            <p className="hidden">
              <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
            </p>

            <label
              className="block font-bold mb-2 text-xs "
              htmlFor="fullname"
            >
              Name
            </label>

            <input
              className="appearance-none block bg-grey-lighter mb-6 p-3 rounded text-grey-darker w-full border border-solid border-very-grey-light"
              id="fullname"
              type="text"
              placeholder=""
            />

            <div className="flex justify-between">
              <div className="w-1/2">
                <label
                  className="block font-bold mb-2 text-xs "
                  htmlFor="email"
                >
                  E-mail
                </label>
                <input
                  className="appearance-none block bg-grey-lighter mb-6 p-3 rounded text-grey-darker w-full border border-solid border-very-grey-light"
                  id="email"
                  type="email"
                  name="email"
                  placeholder=""

                />
              </div>
              <div className="w-1/2 pl-2">
                <label
                  className="block font-bold mb-2 text-xs "
                  htmlFor="phone"
                >
                  Phone
                </label>
                <input
                  className="appearance-none block bg-grey-lighter mb-6 p-3 rounded text-grey-darker w-full border border-solid border-very-grey-light"
                  id="phone"
                  type="text"
                  placeholder=""
                  name="phone"
                />
              </div>
            </div>

            <label
              className="block font-bold mb-2 text-xs "
              htmlFor="message"
            >
              Message
            </label>

            <textarea
              className="appearance-none bg-grey-lighter mb-6 p-3 rounded text-grey-darker w-full border border-solid border-very-grey-light"
              placeholder=""
              name="message"
              rows="8"
            />

            <button type="submit" className="inline-block no-underline text-sm text-center px-4 py-3 leading-none border rounded bg-teal text-white border-teal hover:border-teal-light hover:text-white hover:bg-teal-light hover:mb-2">
              Submit
      </button>

          </form>
        </Section>
        <Footer />
      </div>
    </Layout>
  )
}

export default ContactPage;
